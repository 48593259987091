import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";
import eyeIcon from "../../assets/images/icon-eye.png";
import productPlaceholder from "../../assets/images/product-placeholder.jpg";
import partnerStamp from "../../assets/images/partnerStamp.png";
import { useSelector } from "react-redux";

const ProductItem = ({ title, tag, slug, price, image, onClick, link, previewAction, item, ...props }) => {
    const user = useSelector((state) => state.user);
    const userCity = !user.id
        ? !user.id
        : user.user_addresses && user.user_addresses.length > 0 && user.user_addresses[0].city === "São Paulo";

    return (
        <Container card={props.card} previewAction={previewAction} onClick={onClick}>
            {previewAction && (
                <EyeHover onClick={previewAction}>
                    <img src={eyeIcon} alt="Ícone de Olho" />
                </EyeHover>
            )}

            {item.conservation === "brand_new" && <NeverUsed>Nunca usado</NeverUsed>}

            {item.partner_store && (
                <PartnerStamp>
                    <img style={{ width: "100%" }} src={partnerStamp} alt="Loja parceira" />
                </PartnerStamp>
            )}

            {item.original_price_label && (
                <SaleTag promo>
                    <span>SALE</span>
                </SaleTag>
            )}

            {link ? (
                <>
                    <Link to={link && link.replace("{item.slug}", slug)}>
                        <Image
                            src={image ? `${image}?w=361&h=397&bg=FFFFFF&auto=format,compress` : productPlaceholder}
                            loading="lazy"
                        />
                        <Tag>{tag.toLocaleLowerCase("pt-BR")}</Tag>
                        {item && item.size && <Size>Tamanho: {item.size}</Size>}
                        <Title>{typeof title === "string" ? title.toLocaleLowerCase("pt-BR") : title}</Title>

                        {item.price_new && (
                            <StorePrice>
                                Preço Loja R${" "}
                                {item.price_new.toLocaleString("pt-br", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </StorePrice>
                        )}

                        <PriceContainer>
                            <Price oldPrice={!!item.original_price_label}>
                                R${" "}
                                {item.original_price_label
                                    ? item.original_price_label.toLocaleString("pt-br", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                      })
                                    : price.toLocaleString("pt-br", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                      })}
                            </Price>
                            {!!item.original_price_label && (
                                <Price promoPrice>
                                    R${" "}
                                    {price.toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </Price>
                            )}
                        </PriceContainer>
                        {userCity && item.eligible_for_free_shipping && (
                            <FreeShipping>
                                <FreeShippingSpan>FRETE GRÁTIS SP DISPONÍVEL</FreeShippingSpan>
                            </FreeShipping>
                        )}
                    </Link>
                </>
            ) : (
                <>
                    <Image src={image + "?w=361&h=397&bg=FFFFFF&auto=format,compress"} loading="lazy" />
                    <Tag>{tag.toLocaleLowerCase("pt-BR")}</Tag>

                    {item && item.size && <Size>Tamanho: {item.size}</Size>}

                    <Title>{typeof title === "string" ? title.toLocaleLowerCase("pt-BR") : title}</Title>

                    <Price oldPrice={item.original_price_label}>
                        R${" "}
                        {(!!item.original_price_label ? item.original_price_label || price : price).toLocaleString(
                            "pt-br",
                            {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }
                        )}
                    </Price>
                    {item.original_price_label && (
                        <Price promoPrice>
                            R${" "}
                            {price.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Price>
                    )}
                    {userCity && item.eligible_for_free_shipping && (
                        <FreeShipping>
                            <FreeShippingSpan>FRETE GRÁTIS SP DISPONÍVEL</FreeShippingSpan>
                        </FreeShipping>
                    )}
                </>
            )}
        </Container>
    );
};

const PriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 12px;
`;

const EyeHover = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 0px 2px 0px 0px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: #ecb94b;

    img {
        width: 31px;
        height: 14px;
        object-fit: contain;
    }
`;

const PartnerStamp = styled.div`
    position: absolute;
    width: 60px;
    height: 60px;
    right: 15px;
    bottom: 150px;

    ${isMobileMediaQuery} {
        width: 50px;
        height: 50px;
        right: 10px;
        bottom: 120px;
    }
`;

const Container = styled.div`
    position: relative;
    width: 240px;
    max-width: 100%;
    height: 337px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > a {
        text-decoration: none;
        //width: 240px;
        height: 337px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    ${(props) =>
        props.onClick &&
        css`
            cursor: pointer;
        `}

    &:not(:last-child) {
        margin-right: 32px;
    }

    ${isMobileMediaQuery} {
        width: 100%;
    }

    ${(props) =>
        props.previewAction &&
        css`
            :hover {
                ${EyeHover} {
                    display: flex;
                }
            }
        `}

    ${(props) =>
        props.card &&
        css`
            width: auto;
            padding: 10px 0px 0px 0px;
            cursor: pointer;

            border-radius: 2px;
            background-color: #fff;
            box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
            :hover {
            }

            &:not(:last-child) {
                margin-right: 0px;
            }

            ${isMobileMediaQuery} {
                box-sizing: border-box;
                width: auto;
                //height: 315px;
                padding: 0px;
            }
        `}
`;

const Image = styled.img`
    object-fit: contain;
    overflow: hidden;
    width: 95%;
    height: 100%;
    margin-bottom: 10px;

    ${isMobileMediaQuery} {
        margin-bottom: 9px;
    }
`;

const Tag = styled.span`
    margin-bottom: 12px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    line-height: 2.18;
    letter-spacing: 2.2px;
    text-align: center;
    color: #000000;

    ${isMobileMediaQuery} {
        font-size: 9px;
        letter-spacing: 1.4px;
        margin-bottom: 6px;
    }
`;

const Title = styled.span`
    text-transform: capitalize;
    margin-bottom: 12px;
    font-family: "DMSerifDisplay";
    font-size: 13px;
    font-weight: bold;
    line-height: 1.15;
    text-align: center;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 14px;
        margin-bottom: 8px;
    }
`;

const Size = styled.span`
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.6;
    text-align: center;
    color: #34343b;
    margin-bottom: 6px;

    ${isMobileMediaQuery} {
        font-size: 11px;
    }
`;

const StorePrice = styled.span`
    font-family: "Graphik";
    font-size: 12px;
    text-align: center;
    color: #838383;
    font-weight: 400;
    margin-bottom: 8px;
`;

const Price = styled.span`
    font-family: "Graphik";
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    color: #34343b;
    font-weight: 600;

    ${isMobileMediaQuery} {
        font-size: 14px;
    }

    ${({ oldPrice }) =>
        oldPrice &&
        css`
            text-decoration: line-through;
            color: #e4858c;
            font-size: 14px;
            font-weight: 400;
        `}

    ${({ promoPrice }) =>
        promoPrice &&
        css`
            font-size: 16px;
            color: #e4858c;
        `}
`;

const NeverUsed = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-align: center;
    background-color: #7ca8af;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
`;

const SaleTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-align: center;
    background-color: #7ca8af;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    padding-left: 16px;
    box-sizing: border-box;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    ${({ promo }) =>
        promo &&
        css`
            background-color: #e4858c;
            padding: 14px 37px;
            background-color: transparent;
            transform: rotate(-45deg);
            right: initial;
            left: -30px;

            span {
                z-index: 1;
            }
            &:after {
                z-index: 0;
                border-bottom: 50px solid #e4858c;
                border-left: 50px solid transparent;
                border-right: 50px solid transparent;
                border-top: 50px solid transparent;
            }
        `}
`;

const FreeShipping = styled.div`
    font-family: "Graphik";
    font-size: 11px;
    text-align: center;
    background-color: #f4ced1;
    color: #34343b;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const FreeShippingSpan = styled.span`
    padding: 0px 1px;
`;

export default ProductItem;
