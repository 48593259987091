import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import * as CategoryActions from "../../redux/actions/category";
import Pagination from "../../components/Pagination";
import InternalLoading from "../../components/InternalLoading";
import api from "../../services/api";
import { ContentDelimiter, Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import BackgroundImage from "../../assets/images/categories-background.jpg";
import Header from "./../../components/Header";
import Footer from "../../components/Footer";
import ProductsGrid from "../../components/ProductsGrid";
import CategoryFilters from "../../components/CategoryFilters";
import Loading from "../../components/Loading";
import { tryParse, openPrivacyNTerms } from "../../helpers";

const Category = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const dispatch = useDispatch();
    const category = useSelector((state) => state.category.category);
    const [state, setState] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [currentFilters, setCurrentFilters] = useState(undefined);
    const [locale, setLocale] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const initialPage = searchParams.get("currentPage");
    const [currentPage, setCurrentPage] = useState(initialPage !== null ? initialPage : 1);

    const changeFromStringifyToParse = (filters) => {
        const multipleFilters = ["color", "brand", "price", "from_price", "to_price", "size"];
        for (const multipleFilter of multipleFilters) {
            const filterValue = filters[multipleFilter];
            if (filterValue && typeof filterValue === "string") filters[multipleFilter] = JSON.parse(filterValue);
        }
        return filters;
    };

    const changeFromParseToStringify = (filters) => {
        const multipleFilters = ["color", "brand", "price", "from_price", "to_price", "size"];
        for (const multipleFilter of multipleFilters) {
            const filterValue = filters[multipleFilter];
            if (filterValue && filterValue.length === 0) delete filters[multipleFilter];
            else if (filterValue && typeof filterValue !== "string")
                filters[multipleFilter] = JSON.stringify(filterValue);
        }
        return filters;
    };

    const handlePage = (page) => {
        setCurrentPage(page);
        if (currentFilters) {
            var concatFilter = { ...changeFromParseToStringify(currentFilters), currentPage: page.toString() };
            setSearchParams(concatFilter);
        } else {
            setSearchParams({ currentPage: page.toString() });
        }
    };

    useEffect(() => {
        handleLoadMoreProducts(currentPage);
    }, [currentPage]);

    useEffect(() => {
        var validadeRoute = 0;
        const handleBrowserBack = async () => {
            const vanilla = window.location.search;
            var match = vanilla.match(/[?&]currentPage=([^&]*)/);
            var vanillaPage = match && match[1];
            setCurrentPage(vanillaPage);
            if (searchParams.get("currentPage") && validadeRoute === 0) {
                validadeRoute = 1;
                navigate(-1);
            }
        };
        window.addEventListener("popstate", handleBrowserBack);
        return () => {
            window.removeEventListener("popstate", handleBrowserBack);
        };
    }, []);

    useEffect(() => {
        let queryParams = new URLSearchParams(location.search);
        let filters = {};
        for (var key of queryParams.keys()) {
            let paramValue = queryParams.get(key);
            if (["color", "brand", "price", "from_price", "to_price", "size"].includes(key)) {
                paramValue = tryParse(queryParams.get(key), "self");
            }
            filters = { ...filters, [key]: paramValue };
        }

        queryParams.forEach((value, key) => {
            if (value === null || value === "" || value === "null") {
                delete filters[key];
            }
        });

        if ((!state || !category || category === state || state.filters || category.filters) && pathname) {
            loadProducts(filters);
        } else {
            setState(category);
            if (Object.values(filters).length > 0) {
                handleApplyFilters({ ...filters, currentPage });
            } else {
                setCurrentFilters({});
            }
        }
        //eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        if (category?.scrollY && state) {
            setTimeout(() => window.scrollTo({ top: category?.scrollY }), 50);
        }
        if (state) {
            dispatch(CategoryActions.saveLastPosition(state, pathname));
        }
        // eslint-disable-next-line
    }, [state]);

    const handleScrollYPosition = (position) => {
        dispatch(
            CategoryActions.saveLastPosition(
                {
                    ...category,
                    scrollY: position,
                },
                pathname
            )
        );
    };

    const loadProducts = async (filters) => {
        let hasFilters = Object.values(filters).length > 0;
        if (!hasFilters) {
            let pathCategory = "categorias";

            if (pathname.includes("/ocasioes")) {
                pathCategory = "ocasioes";
            } else if (pathname.includes("/marcas")) {
                pathCategory = "marcas";
            } else if (pathname.includes("/loja")) {
                pathCategory = "loja";
            }
            let requestParams = {
                path:
                    (params.category || params.section) &&
                    `/${pathCategory}/${params.section}${params.category ? `/${params.category}` : ""}`,
                type: filters.type,
                search: filters.search || undefined,
                sort:
                    Object.keys(filters).length === 0 && pathname === "/produtos" ? "items.created_at.desc" : undefined,
                page: currentPage,
            };

            if (params.category === "novidades") {
                requestParams.path = `/novidades/${params.section}`;
            } else if (pathname === "/novidades" || pathname === "/categorias/novidades") {
                requestParams.path = `/novidades`;
            } else if (pathname === "/nunca-usados" || pathname === "/categorias/nunca-usados") {
                requestParams.path = `/nunca-usados`;
            } else if (params.category === "promocoes" || pathname === "/promocoes") {
                requestParams.path = `/promocoes/${params.section}`;
            } else if (pathname === "/livelo") {
                requestParams.path = `/livelo`;
            }

            let response = await api
                .get(`items`, {
                    params: requestParams,
                })
                .catch((err) => err.response);

            if (response.status === 200) {
                if (pathname === "/produtos" && filters && filters.search) {
                    response.data.content = {
                        title: "search",
                    };
                } else if (pathname === "/produtos") {
                    response.data.content = {
                        title: "Produtos",
                    };
                }

                setState({ ...response.data });
                setCurrentFilters({});
            } else {
                setState({
                    items: [],
                    pagination: {},
                    filters: {},
                });
                setCurrentFilters({});
            }

            let list = `productsSearchFiltersPag=${response.data?.pagination?.current}${
                pathname + window.location.search + window.location.hash
            }`;
            setLocale(list);

            let responseSearch = [];
            response.data.items.map((item, index) =>
                responseSearch.push({
                    name: item.name,
                    id: item.id,
                    price: item.price_label,
                    brand: item.brand.name,
                    category: item.item_category.name,
                    position: index + 1,
                    list: list,
                })
            );

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "productImpression",
                ecommerce: {
                    currencyCode: "BRL",
                    impressions: responseSearch,
                },
            });
        } else {
            handleApplyFilters({ ...filters });
        }
    };

    const handleLoadMoreProducts = async (vanillaPage) => {
        if (state && state.pagination && state.pagination.base_path) {
            window.scroll({ top: 0, behavior: "smooth" });
            setLoading(true);
            let pathApi = state.pagination.base_path.replace("/v1/", "");
            let response = await api.get(pathApi + "&page=" + vanillaPage).catch((err) => err.response);
            setLoading(false);
            if (response.status === 200) {
                setState({
                    ...state,
                    items: response.data.items,
                    pagination: response.data.pagination,
                });
                let list = `productsSearchFiltersPag=${response.data?.pagination?.current}${
                    pathname + window.location.search + window.location.hash
                }`;
                setLocale(list);

                let responseSearch = [];
                response.data.items.map((item, index) =>
                    responseSearch.push({
                        name: item.name,
                        id: item.id,
                        price: item.price_label,
                        brand: item.brand.name,
                        category: item.item_category.name,
                        position: index + 1,
                        list: list,
                    })
                );

                // eslint-disable-next-line
                dataLayer.push({ ecommerce: null });
                // eslint-disable-next-line
                dataLayer.push({
                    event: "productImpression",
                    ecommerce: {
                        currencyCode: "BRL",
                        impressions: responseSearch,
                    },
                });
            }
        }
    };

    const handleApplyFilters = async (filters, currentFilter, isFromCallback) => {
        let defaultFilters = currentFilters;
        if (isFromCallback) delete filters["currentPage"];

        if (currentFilter) delete defaultFilters[currentFilter];

        Object.entries(filters).forEach((item) => {
            if (
                item[1] === undefined ||
                item[1] === null ||
                (item[1].toString() === "" && typeof item[1] !== "object")
            ) {
                delete filters[item[0]];
            }
        });

        filters = { ...defaultFilters, ...filters };
        let updatedFilters = { ...filters };
        if (filters.price && filters.price.length > 0) {
            const lastPriceIndex = filters.price.length - 1;
            updatedFilters = {
                ...updatedFilters,
                from_price: filters.price[lastPriceIndex][0],
                to_price: filters.price[lastPriceIndex][1],
            };
            delete updatedFilters.price;
            filters = updatedFilters;
        }

        if (Object.keys(filters).length === 0 && pathname === "/produtos") {
            filters.order = "items.created_at.desc";
        }

        let pathCategory = "categorias";

        if (pathname.indexOf("/ocasioes") >= 0) pathCategory = "ocasioes";
        else if (pathname.indexOf("/marcas") >= 0) pathCategory = "marcas";
        else if (pathname.indexOf("/loja") >= 0) pathCategory = "loja";

        let requestParams = {
            path:
                (params.category || params.section) &&
                `/${pathCategory}/${params.section}${params.category ? `/${params.category}` : ""}`,
            type: filters.type,
            search: filters.search || undefined,

            brands:
                filters.brand && filters.brand.length > 0
                    ? typeof filters.brand === "object"
                        ? `[${filters.brand.join(",")}]`
                        : `${filters.brand}`
                    : undefined,
            colors:
                filters.color && filters.color.length > 0
                    ? typeof filters.color === "object"
                        ? `[${filters.color.join(",")}]`
                        : `${filters.color}`
                    : undefined,
            from_price: filters.from_price ? filters.from_price : undefined,
            to_price: filters.to_price ? filters.to_price : undefined,
            sizes:
                filters.size && filters.size.length > 0
                    ? typeof filters.size === "object"
                        ? `[${JSON.stringify(filters.size)}]`
                        : filters.size
                    : undefined,
            sort: filters.order && filters.order.length > 0 ? filters.order : undefined,
            page: currentPage,
        };

        if (params.category === "novidades") {
            requestParams.path = `/novidades/${params.section}`;
        } else if (pathname === "/novidades" || pathname === "/categorias/novidades") {
            requestParams.path = `/novidades`;
        } else if (pathname === "/nunca-usados" || pathname === "/categorias/nunca-usados") {
            requestParams.path = `/nunca-usados`;
        } else if (params.category === "promocoes" || pathname === "/promocoes") {
            requestParams.path = `/promocoes/${params.section}`;
        } else if (pathname === "/livelo") {
            requestParams.path = `/livelo`;
        }

        if (!state || category === state || (state && state.content.path !== pathname)) {
            let response = await api
                .get(`items`, {
                    params: requestParams,
                })
                .catch((err) => err.response);
            if (response.status === 200) {
                let list = `productsSearchFiltersPag=${response.data?.pagination?.current}${
                    pathname + window.location.search + window.location.hash
                }`;
                setLocale(list);

                let responseSearch = [];
                response.data.items.map((item, index) =>
                    responseSearch.push({
                        name: item.name,
                        id: item.id,
                        price: item.price_label,
                        brand: item.brand.name,
                        category: item.item_category.name,
                        position: index + 1,
                        list: list,
                    })
                );

                // eslint-disable-next-line
                dataLayer.push({ ecommerce: null });
                // eslint-disable-next-line
                dataLayer.push({
                    event: "productImpression",
                    ecommerce: {
                        currencyCode: "BRL",
                        impressions: responseSearch,
                    },
                });

                if (response.data.content === null) {
                    if (pathname === "/produtos" && filters && filters.search) {
                        response.data.content = {
                            title: "search",
                        };
                    } else if (pathname === "/produtos") {
                        response.data.content = {
                            title: "Produtos",
                        };
                    }
                }

                setCurrentFilters(filters);
                setState(response.data);
                setSearchParams({
                    ...changeFromParseToStringify(filters),
                    currentPage: isFromCallback ? 1 : currentPage,
                });
                if (isFromCallback) setCurrentPage(1);
            } else {
                setState({
                    items: [],
                    pagination: {},
                    filters: {},
                });
                setCurrentFilters({});
            }
        } else if (!state && category) {
            setState(category);
        }
    };

    let pageTitle = state?.content?.title ? (
        state?.content?.title === "search" ? (
            <>Resultados para: {currentFilters.search}</>
        ) : (
            (state?.content?.title || "").replace(/(-)+/g, " ").toLocaleLowerCase("pt-BR")
        )
    ) : (
        (params.category || params.section || "").replace(/(-)+/g, " ").toLocaleLowerCase("pt-BR")
    );

    if (
        pathname.includes("/novidades") ||
        pathname.includes("/promocoes") ||
        pathname.includes("/nunca-usados") ||
        pathname.includes("/livelo")
    ) {
        pageTitle = state?.content?.title;
    }

    return (
        <>
            {loading && <InternalLoading />}

            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />

            {state === undefined || currentFilters === undefined ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    {state.content && (
                        <Helmet>
                            {state.content.page_title && (
                                <title>
                                    {pathname.indexOf("/novidades") >= 0
                                        ? "Brechó infantil de roupas e acessórios usados para bebês e crianças"
                                        : state.content.page_title}
                                </title>
                            )}
                            {state.content.page_title && (
                                <meta
                                    property="og:title"
                                    content={
                                        pathname.indexOf("/novidades") >= 0
                                            ? "Brechó infantil de roupas e acessórios usados para bebês e crianças"
                                            : state.content.page_title
                                    }
                                />
                            )}
                            {state.content.meta_description && (
                                <meta
                                    name="description"
                                    content={
                                        pathname.indexOf("/novidades") >= 0
                                            ? "Roupas e acessórios seminovos para bebês, crianças e adolescentes. O brechó infantil com as melhores marcas nacionais e internacionais"
                                            : state.content.meta_description
                                    }
                                />
                            )}
                            {state.content.meta_description && (
                                <meta
                                    property="og:description"
                                    content={
                                        pathname.indexOf("/novidades") >= 0
                                            ? "Roupas e acessórios seminovos para bebês, crianças e adolescentes. O brechó infantil com as melhores marcas nacionais e internacionais"
                                            : state.content.meta_description
                                    }
                                />
                            )}
                            <meta property="og:url" content={window.location.href} />
                            <meta
                                name="keywords"
                                content="brechó, brechó infantil, brechó bebês, brechós, brechós infantis, roupa, bebê, bebê conforto, body, berço, enxoval, usado, segunda mão, novo, seminovo, comprar, vender, zara, adidas, croc, gap, gucci, fanttina, disney"
                            />
                        </Helmet>
                    )}

                    <Flex desktop minWidth="1222px" background="#fff">
                        <Flex
                            width="1222px"
                            minHeight={!pathname.includes("search") ? "230px" : "0"}
                            direction="column"
                            padding={!pathname.includes("search") ? "32px" : "0"}
                            margin="auto 0 auto 0"
                            alignItems="flex-start"
                            cursor={pathname.includes("/livelo") ? "pointer" : "auto"}
                            onClick={() => pathname.includes("/livelo") && openPrivacyNTerms("livelo")}
                            background={`url(${
                                state.content && state.content.image_url
                                    ? state.content.image_url
                                    : !pathname.includes("search")
                                    ? BackgroundImage
                                    : ""
                            }) center / cover no-repeat`}
                            boxSizing="border-box"
                        >
                            {pageTitle && state.content && (
                                <Flex
                                    alignItems="flex-start"
                                    direction="column"
                                    background="#fff"
                                    padding="32px 24px"
                                    minWidth="30%"
                                    maxWidth="50%"
                                >
                                    {pageTitle && <CategoryTitle>{pageTitle}</CategoryTitle>}

                                    {state.content && state.content.subtitle && (
                                        <CategorySubTitle>
                                            {state.content.subtitle.toLocaleLowerCase("pt-BR")}
                                        </CategorySubTitle>
                                    )}

                                    {state.content && state.content.description && (
                                        <CategoryText>{state.content.description}</CategoryText>
                                    )}
                                </Flex>
                            )}
                        </Flex>
                    </Flex>

                    <Flex alignItems="flex-start" direction="column" mobile background="#F7F9F9" padding="16px 0px">
                        <Flex width="100%" direction="column" margin="auto auto 16px auto" alignItems="center">
                            <CategoryTitle>{pageTitle}</CategoryTitle>
                        </Flex>

                        {!pathname.includes("search") && (
                            <img
                                width="100%"
                                height="auto"
                                src={
                                    state.content && (state.content.image_url || state.content.image_mobile_url)
                                        ? isMobile && state.content.image_mobile_url
                                            ? state.content.image_mobile_url
                                            : state.content.image_url
                                        : BackgroundImage
                                }
                                alt="Criança feliz correndo com braços abertos"
                            />
                        )}

                        {state.content && (state.content.subtitle || state.content.description) && (
                            <Flex
                                width="calc(100% - 80px)"
                                direction="column"
                                margin="30px auto 28px auto"
                                alignItems="center"
                                padding="0px 40px"
                            >
                                {state.content && state.content.subtitle && (
                                    <CategorySubTitle>
                                        {state.content.subtitle.toLocaleLowerCase("pt-BR")}
                                    </CategorySubTitle>
                                )}
                                {state.content && state.content.description && (
                                    <CategoryText>{state.content.description}</CategoryText>
                                )}
                            </Flex>
                        )}
                    </Flex>

                    <ContentDelimiter>
                        <Flex direction="column">
                            <Flex
                                alignItems="flex-end"
                                width={isMobile ? "100%" : "1222px"}
                                margin={!isMobile && "30px auto 0px auto"}
                            >
                                <CategoryFilters
                                    filters={state.filters}
                                    applyCallback={(newSelectedFilters, currentFilter) => {
                                        handleApplyFilters(newSelectedFilters, currentFilter, true);
                                    }}
                                    defaultSelectedFilters={changeFromStringifyToParse(currentFilters)}
                                />
                            </Flex>
                        </Flex>
                        {state.items.length > 0 ? (
                            <>
                                <ProductsGrid
                                    locale={locale}
                                    products={state.items}
                                    handleScrollYPosition={handleScrollYPosition}
                                />

                                {state.pagination.pages > 0 && (
                                    <Pagination
                                        totalPages={state.pagination.pages}
                                        currentPage={state.pagination.current}
                                        changePage={handlePage}
                                    />
                                )}

                                <Flex
                                    direction="column"
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    width="100%"
                                >
                                    {documentToReactComponents(state.content && state.content.html)}
                                </Flex>
                            </>
                        ) : (
                            <Flex>
                                <CategoryNotFound>vitrine não encontrada.</CategoryNotFound>
                            </Flex>
                        )}
                    </ContentDelimiter>
                </>
            )}
            <Footer />
        </>
    );
};

const CategoryTitle = styled.h1`
    margin: 0px;
    margin-bottom: 28px;
    text-transform: capitalize;

    font-family: "DMSerifDisplay";
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 32px;
        text-align: center;
        margin: 0px;
    }

    :only-child {
        margin: 0px;
    }
`;

const CategorySubTitle = styled.h2`
    margin: 0px;
    margin-bottom: 24px;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: left;
    color: #e4858c;

    ${isMobileMediaQuery} {
        font-size: 11px;
        font-weight: 900;
        text-align: center;
    }
`;

const CategoryText = styled.p`
    margin: 0px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 15px;
        text-align: center;
    }
`;

const CategoryNotFound = styled.p`
    margin: 24px 0px;
    font-family: "SackersGothicStd";
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: left;
    ${isMobileMediaQuery} {
        font-size: 14px;
        font-weight: 900;
        text-align: center;
    }
`;

export default Category;
