import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";

import ApproveCallHero from "../../assets/images/approval-modal.png";
import modalHero from "../../assets/images/lote-approve-hero.jpg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

import Button from "../Button";

const SellApprovalModal = ({ show, callback, approved, callToAction, ...props }) => {
    const bodyElement = document.querySelector("body");

    const [modalActive, setModalActive] = useState(false);

    useEffect(() => {
        return () => {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        show !== null && show !== undefined && show !== modalActive && setModalActive(show);

        // eslint-disable-next-line
    }, [show]);

    useEffect(() => {
        if (modalActive) {
            let top = window.scrollY;

            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        // eslint-disable-next-line
    }, [modalActive]);

    const handleClose = () => {
        setModalActive(false);

        callback && callback();
    };

    return (
        modalActive && (
            <Container>
                <Backdrop onClick={handleClose} />
                <Modal>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>

                    <Flex flex="1" height="100%" direction="column" margin="auto 0px auto 0">
                        <ModalImage
                            src={callToAction ? ApproveCallHero : modalHero}
                            alt="Pedido realizado com sucesso!"
                        />
                    </Flex>

                    <Flex
                        width={isMobile ? "100%" : "400px"}
                        height="100%"
                        background="#7CA8AF"
                        direction="row"
                        alignItems="flex-start"
                        padding={isMobile ? "30px 25px" : "40px"}
                        boxSizing="border-box"
                    >
                        {!callToAction && approved && (
                            <Flex direction="column" height="100%" rowGap="24px">
                                <ScrollView>
                                    <ModalText>
                                        <ScrollView>
                                            Após a validação, suas peças serão fotografadas e em breve estarão a venda
                                            tanto no site quanto no Box.
                                            <br />
                                            <ul>
                                                <li>
                                                    Caso a proposta não seja respondida em até 7 dias corridos, será
                                                    aprovada automaticamente e as peças seguirão para venda.
                                                </li>
                                                <li>
                                                    Você tem 10 dias corridos para retirar as peças não aprovadas. Após
                                                    este prazo suas peças serão automaticamente doadas. A retirada é das
                                                    peças é de responsabilidade do vendedor. Endereço: Rua Andréa
                                                    Paulinetti, 53 - CEP 04707-050, Brooklin, São Paulo/SP.
                                                </li>
                                                <li>
                                                    É obrigatório que o CPF da conta bancária cadastrada seja o mesmo do
                                                    cadastro de usuário no site
                                                </li>
                                            </ul>
                                        </ScrollView>
                                    </ModalText>
                                </ScrollView>
                                <Button
                                    width="240px"
                                    margin="0"
                                    value="voltar para a home"
                                    onClick={() => handleClose()}
                                />
                            </Flex>
                        )}

                        {!callToAction && !approved && (
                            <>
                                <Title>que pena que você não aceitou vender com a repetit</Title>
                                <Separator />
                                <ModalText>
                                    Que pena que a proposta não foi aceita. Você já falou com nossa equipe para revisar
                                    os valores? Entre em contato agora mesmo. Caso opte mesmo por retirar as peças
                                    analisadas, gostaríamos de relembrar que fizemos tudo com muito carinho e conforme
                                    indicado nos Termos e Condições será cobrada uma taxa para devolução. Nossa equipe
                                    entrará em contato para agendar a retirada.
                                </ModalText>

                                <Button
                                    width="240px"
                                    margin="0"
                                    value="voltar para a home"
                                    onClick={() => handleClose()}
                                />
                            </>
                        )}

                        {callToAction && (
                            <>
                                <Title>comece a vender agora com a re petit</Title>
                                <Separator />
                                <ModalText>
                                    Já avaliamos suas peças e estimamos os valores, você só precisa aprovar a proposta.
                                    Vem circular com a Re Petit.
                                </ModalText>

                                <Button width="240px" margin="0" value="ver proposta" onClick={() => handleClose()} />
                            </>
                        )}
                    </Flex>
                </Modal>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
        overflow-y: auto;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
`;

const Modal = styled.div`
    position: relative;
    width: 1000px;
    height: 550px;

    max-width: 90vw;
    max-height: 80vh;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${isMobileMediaQuery} {
        padding: 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 90vw;
        max-height: initial;
        height: auto;
    }
`;

const ScrollView = styled.div`
    flex: 1;
    overflow-y: auto;
    height: 100%;
    max-height: 450px;
    padding-right: 4px;
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-track {
        background: #f4f4f5;
    }
    ::-webkit-scrollbar-thumb {
        background: #cacad1;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    ${isMobileMediaQuery} {
        width: 100%;
    }
`;

const CloseButton = styled.button`
    padding: 0px;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0px;
    right: 0px;
    margin: 19px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.8);
        path {
            fill: #fff;
        }
    }
`;

const ModalImage = styled.img`
    padding: 30px;
    max-width: 80%;
    margin: auto;

    ${isMobileMediaQuery} {
        margin-top: 40px;
        /* padding: 0px; */
    }
`;

const Title = styled.h1`
    margin: 0px auto 16px 0px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    line-height: 1.5;
    letter-spacing: 2.8px;
    text-align: left;
    color: #ffffff;
`;

const Separator = styled.hr`
    width: 40px;
    height: 3px;
    border: 0;
    background-color: #313139;
    margin: 0px auto 15px 0px;
`;

const ModalText = styled.p`
    margin: 0px;
    font-size: 14px;
    line-height: 1.35;
    text-align: left;
    color: #ffffff;

    :not(:last-child) {
        margin-bottom: 40px;
    }
`;

export default SellApprovalModal;
