import React, { useEffect, useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../services/api";
import { Flex, notMobileMediaQuery, sizes } from "./../../helpers/styles";
import { ReactComponent as BackButton } from "../../assets/images/bt-voltar.svg";
import ExpandableContainer from "../ExpandableContainer";
import SubMenuMobile from "../SubMenuMobile";

function NavigationMenuMobile({ navigationItems, closeMenu, ...props }) {
    const bodyElement = document.querySelector("body");
    const navigate = useNavigate();
    const scrollRef = createRef();

    const [menuOpen, setMenuOpen] = useState(props.menuOpen);
    const [hideMain, setHideMain] = useState(false);
    const [hideSubCategory, setHideSubCategory] = useState(false);
    const [menuStatus, setMenuStatus] = useState({
        categories: false,
        brands: false,
        occasions: false,
        findings: false,
    });
    const [brands, setBrands] = useState();
    const [currentSubMenu, setCurrentSubMenu] = useState();
    const [currentSubCategory, setCurrentSubCategory] = useState();
    const [state, setState] = useState({});

    useEffect(() => {
        loadMenu();

        // eslint-disable-next-line
    }, []);

    const loadMenu = async () => {
        let response = await api.get("/menu?clearCache=true");

        if (response.status === 200) {
            setState({ ...response.data });
            let key = Object.keys(response.data.brands).find((key) => key === "#");
            setBrands(Object.values(response.data.brands[key]));
        } else {
            setState({
                categories: {},
                brands: {},
                moments: [],
                boxes: [],
                blog_posts: [],
            });
        }
    };

    useEffect(() => {
        if (menuOpen) {
            let top = window.scrollY;

            bodyElement.style.position = "sticky";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
            bodyElement.style.overflowY = "hidden";
        } else {
            const scrollY = bodyElement.style.top;
            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";
            bodyElement.style.overflowY = "auto";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }
        // eslint-disable-next-line
    }, [menuOpen]);

    useEffect(() => {
        props.menuOpen !== menuOpen && setMenuOpen(props.menuOpen);

        // eslint-disable-next-line
    }, [props.menuOpen]);

    useEffect(() => {
        if (menuOpen) {
            props.handleChangeMenuItem();
        }
        // eslint-disable-next-line
    }, [menuOpen]);

    const handleClickSubMenu = (active, menu) => {
        setHideMain(active);
        setHideSubCategory(active);
        setCurrentSubMenu(menu);
        setCurrentSubCategory(menu);
        scrollRef.current.scrollTo(0, 0);
    };

    const handleClickSubCategoryMenu = (active, menu) => {
        setHideSubCategory(active);
    };

    const renderSubCategory = () => {
        if ((currentSubCategory !== "") & (currentSubCategory !== null)) {
            return (
                <Flex width="100%" height="100%" justifyContent="flex-start" alignItems="center" direction="column">
                    <SubMenuMobile
                        title="Todos produtos"
                        width="calc(100% - 40px)"
                        onClick={() => {
                            navigate(`/categorias/${currentSubCategory}/novidades`);
                            closeMenu();
                        }}
                    />

                    <SubMenuMobile
                        title="Nunca usados"
                        width="calc(100% - 40px)"
                        onClick={() => {
                            navigate(`/nunca-usados`);
                            closeMenu();
                        }}
                    />
                    <SubMenuMobile
                        title="Promoções"
                        width="calc(100% - 40px)"
                        onClick={() => {
                            navigate(`/categorias/${currentSubCategory}/promocoes`);
                            closeMenu();
                        }}
                    />
                    <SubMenuMobile
                        title="Outlet"
                        width="calc(100% - 40px)"
                        onClick={() => {
                            navigate(`/outlet`);
                            closeMenu();
                        }}
                    />

                    <ExpandableContainer
                        title="Categorias"
                        width="calc(100% - 40px)"
                        maxHeight="180px"
                        padding="0 20px"
                        overFlowY="auto"
                        background="transparent"
                        value={false}
                        onChange={(value) =>
                            value !== undefined &&
                            setMenuStatus({
                                categories: value,
                            })
                        }
                    >
                        <Flex direction="column" alignItems="flex-start">
                            <Links vertical>
                                {state.categories &&
                                    state.categories[currentSubCategory] &&
                                    state.categories[currentSubCategory].map((item, index) => (
                                        <a key={index} href={`/categorias/${currentSubCategory}/${item.slug}`}>
                                            {item.name}
                                        </a>
                                    ))}
                            </Links>
                        </Flex>
                    </ExpandableContainer>

                    <ExpandableContainer
                        title="Marcas"
                        width="calc(100% - 40px)"
                        maxHeight="180px"
                        padding="0 20px"
                        background="transparent"
                        value={menuStatus.brands}
                        overFlowY="auto"
                        height="270px"
                        onChange={(value) =>
                            value !== undefined &&
                            setMenuStatus({
                                brands: value,
                            })
                        }
                    >
                        <Flex direction="column" alignItems="flex-start">
                            <Links vertical>
                                {brands &&
                                    brands
                                        .filter((brand) => brand.types.includes(currentSubCategory))
                                        .map(
                                            (item, index) =>
                                                item.name.toLowerCase() !== "repetit" && (
                                                    <a key={index} href={`/marcas/${currentSubCategory}/${item.slug}`}>
                                                        {item.name.charAt(0).toUpperCase() +
                                                            item.name.slice(1).toLowerCase()}
                                                    </a>
                                                )
                                        )}
                            </Links>
                        </Flex>
                    </ExpandableContainer>

                    {currentSubCategory !== "objetos" && (
                        <ExpandableContainer
                            title="Ocasiões"
                            width="calc(100% - 40px)"
                            maxHeight="180px"
                            padding="0 20px"
                            background="transparent"
                            value={menuStatus.occasions}
                            overFlowY="auto"
                            height="270px"
                            onChange={(value) =>
                                value !== undefined &&
                                setMenuStatus({
                                    occasions: value,
                                })
                            }
                        >
                            <Flex direction="column" alignItems="flex-start">
                                <Links vertical>
                                    {state.moments &&
                                        Object.values(state.moments).map((item, index) => (
                                            <a key={index} href={`/ocasioes/${currentSubCategory}/${item.slug}`}>
                                                {item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()}
                                            </a>
                                        ))}
                                </Links>
                            </Flex>
                        </ExpandableContainer>
                    )}
                </Flex>
            );
        } else {
            return;
        }
    };

    return (
        state && (
            <Menu menuOpen={menuOpen}>
                <ScrollContainer ref={scrollRef}>
                    <HideContainer hide={hideMain}>
                        <SubMenuMobile
                            title={"A Re Petit"}
                            width="calc(100% - 40px)"
                            padding={window.scrollY < 30 ? "70px 20px 0px 20px" : "0 20px"}
                            onClick={() => {
                                navigate("/#sobre");
                                setTimeout(() => {
                                    const element = document.getElementById("sobre");
                                    element ? element.scrollIntoView() : navigate("/#sobre");
                                }, 200);
                                closeMenu();
                            }}
                        />

                        <ExpandableContainer
                            title="Compre"
                            width="calc(100% - 40px)"
                            background="transparent"
                            value={menuStatus.categories}
                            borderBottom={false}
                            onChange={(value) =>
                                value !== undefined &&
                                setMenuStatus({
                                    categories: value,
                                })
                            }
                        >
                            {!currentSubMenu && (
                                <Flex direction="column" alignItems="flex-start">
                                    <SubMenuMobile
                                        title="Todos produtos"
                                        width="calc(100% - 20px)"
                                        onClick={() => {
                                            navigate(`/categorias/novidades`);
                                            closeMenu();
                                        }}
                                    />
                                    <SubMenuMobile
                                        title="Nunca usados"
                                        width="calc(100% - 20px)"
                                        onClick={() => {
                                            navigate(`/nunca-usados`);
                                            closeMenu();
                                        }}
                                    />
                                    <SubMenuMobile
                                        title="Promoções"
                                        width="calc(100% - 20px)"
                                        onClick={() => {
                                            navigate(`/promocoes`);
                                            closeMenu();
                                        }}
                                    />
                                    <SubMenuMobile
                                        title="Outlet"
                                        width="calc(100% - 20px)"
                                        onClick={() => {
                                            navigate(`/outlet`);
                                            closeMenu();
                                        }}
                                    />
                                    {[
                                        {
                                            value: "menina",
                                            name: "Menina | 2 - 14 Anos",
                                        },
                                        {
                                            value: "menino",
                                            name: "Menino | 2 - 14 Anos",
                                        },
                                        {
                                            value: "bebe-menino",
                                            name: "Bebê Menino | 0 Meses - 2 Anos",
                                        },
                                        {
                                            value: "bebe-menina",
                                            name: "Bebê Menina | 0 Meses - 2 Anos",
                                        },
                                        {
                                            value: "objetos",
                                            name: "Objetos e Brinquedos",
                                        },
                                    ].map((item, index) => (
                                        <SubMenuMobile
                                            key={`subMenuItem_${index}`}
                                            title={item.name}
                                            width="calc(100% - -10px)"
                                            onChange={(e) => {
                                                // handleClickSubCategoryMenu(
                                                //     e,
                                                //     item.value
                                                // );
                                                handleClickSubMenu(e, item.value);
                                            }}
                                        >
                                            {renderSubCategory(item.value)}
                                        </SubMenuMobile>
                                    ))}
                                </Flex>
                            )}
                        </ExpandableContainer>

                        <ExpandableContainer
                            title="Quero vender"
                            width="calc(100% - 40px)"
                            background="transparent"
                            borderBottom={false}
                        >
                            <SubMenuMobile
                                title="Como funciona"
                                width="calc(100% - 20px)"
                                onClick={() => {
                                    navigate(`/desapegue`);
                                    closeMenu();
                                }}
                            />
                            <SubMenuMobile
                                title="O que vender"
                                width="calc(100% - 20px)"
                                onClick={() => {
                                    navigate(`/o-que-vender`);
                                    closeMenu();
                                }}
                            />
                            <SubMenuMobile
                                title="Pagamentos"
                                width="calc(100% - 20px)"
                                onClick={() => {
                                    navigate(`/pagamentos`);
                                    closeMenu();
                                }}
                            />
                            <SubMenuMobile
                                title="Formulário venda"
                                width="calc(100% - 20px)"
                                onClick={() => {
                                    navigate(`/quero-vender`);
                                    closeMenu();
                                }}
                            />
                        </ExpandableContainer>

                        {[
                            {
                                label: "Peça seu box",
                                action: () => {
                                    navigate("/box");
                                    closeMenu();
                                },
                            },
                            {
                                label: "Lojas Influencers",
                                action: () => {
                                    navigate("/lojas-influencers");
                                    closeMenu();
                                },
                            },
                            {
                                label: "Blog",
                                action: () => {
                                    window.location.replace("/blog");
                                    closeMenu();
                                },
                            },
                            {
                                label: "Outlet",
                                action: () => {
                                    navigate("/outlet");
                                    closeMenu();
                                },
                            },
                            {
                                label: "Sale",
                                action: () => {
                                    navigate("/promocoes");
                                    closeMenu();
                                },
                            },
                        ].map((item, index) => (
                            <SubMenuMobile
                                key={`subMenuItem_${index}`}
                                title={item.label}
                                width="calc(100% - 20px)"
                                padding="0 20px"
                                onClick={item.action}
                                highlight={item.highlight}
                                secondary={item.secondary}
                            />
                        ))}
                    </HideContainer>
                    {currentSubMenu && (
                        <>
                            <SubMenuTitle background="#F7F9F9" padding="70px 20px 12px 20px" width="calc(100% - 40px)">
                                <BackButton onClick={() => handleClickSubMenu(false, "")} />

                                <h1>
                                    {currentSubCategory === "objetos" ? "Objetos e Brinquedos" : currentSubCategory}
                                </h1>
                            </SubMenuTitle>

                            {renderSubCategory()}
                        </>
                    )}
                </ScrollContainer>
            </Menu>
        )
    );
}

const Menu = styled.div`
    top: ${sizes.header}px;
    left: 0px;
    right: 0px;
    bottom: 100%;
    width: 100%;
    height: auto;
    position: fixed;
    background-color: white;
    z-index: -1;
    transition: 0.35s all ease-in-out;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    ${(props) =>
        props.menuOpen &&
        `
        bottom: 0px;
    `}
    ${notMobileMediaQuery} {
        display: none !important;
    }
`;
//min-height: calc(480px + 140px);
const ScrollContainer = styled.div`
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 800px;
`;

const Links = styled(Flex)`
    & > a :not(:last-child) {
        margin-right: 14px;
    }
    ${(props) =>
        props.vertical &&
        `
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        & > a {            
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: left;
            color: #676778;
            :not(:last-child) {
                margin-bottom: 8px;
            }
        }
    `}
`;

const HideContainer = styled(Flex)`
    transition: 0.35s all ease-in-out;
    position: absolute;
    top: 0px;
    left: 0%;
    right: 0%;
    ${(props) =>
        props.sub &&
        `
        margin-top: 60.5px;
    `}
    ${(props) =>
        props.hide &&
        `
        top: 0px;
        left: -100%;
        right: 100%;
    `}
`;

const SubMenuTitle = styled(Flex)`
    & > h1 {
        margin: 0;
        margin-left: auto;
        font-family: "SackersGothicStd";
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.25;
        letter-spacing: 3.2px;
        text-align: right;
        color: #676778;
    }
`;

export default NavigationMenuMobile;
