import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PrivateRoute from "./components/PrivateRoute";
import { validSession } from "./helpers";
import { generateUUID } from "./components/Functions";
import api from "./services/api";
import * as LoginModalActions from "./redux/actions/loginModal";
import * as UserActions from "./redux/actions/user";
import * as ModalActions from "./redux/actions/modal";
import { GlobalStyle, isMobileMediaQuery } from "./helpers/styles";
import {
    Home,
    Influencers,
    Outlet,
    Category,
    Desapegue,
    Marcas,
    Pagamentos,
    Recreio,
    BlogPost,
    Signup,
    QuizPage,
    Product,
    Cart,
    Faq,
    Account,
    Box,
    Checkout,
    CheckoutSuccess,
    SellerSignup,
    PasswordReset,
} from "./pages/";
import LoginVerification from "./pages/LoginVerification";
import LoginModal from "./components/LoginModal";
import CookiesModal from "./components/CookiesModal";
import AlertModal from "./components/AlertModal";
import WhatsappIcon from "./assets/images/wpp-logo.png";
import { useWatchUtm } from "./hooks/WatchUtm";

function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.ui.modalReducer);
    const user = useSelector((state) => state.user);
    const [authenticated, setAuthenticated] = useState(false);
    const [currentRoute] = useState("");
    useWatchUtm();

    useEffect(() => {
        // eslint-disable-next-line
        let userSessionServerData = window.userCurrentServerData(user);
        const fbcEventId = generateUUID();
        const fbcEventTime = Math.round(Date.now() / 1000);

        window.fbq("track", "PageView", {}, { eventID: fbcEventId });

        api.post("/facebook_ads_event", {
            data: [
                {
                    event_name: "PageView",
                    event_id: fbcEventId,
                    event_time: fbcEventTime,
                    event_source_url: window.location.href,
                    action_source: "website",
                    user_data: userSessionServerData,
                    custom_data: {},
                },
            ],
        })
            .then((res) => console.log("EVENTO PageView", res.data))
            .catch((res) => res.response);

        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "virtualPageview",
            page: document.title,
            type: "Website",
            environment: process.env.REACT_APP_ENV,
            user: [
                {
                    name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                    username: user.slug ? user.slug : "",
                    user_id: user.id ? user.id : "",
                    email: user.email ? user.email : "",
                    language: "pt-br",
                    returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                },
            ],
        });

        setAuthenticated(validSession());
        window.scrollTo({ top: 0 });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user && user.first_name && !user.cpf) navigate("/cadastro/verificar");
    }, [user]);

    const memoizedApp = useMemo(
        () => (
            <div className="app">
                <GlobalStyle />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/lojas-influencers" element={<Influencers />} />
                    <Route path="/outlet" element={<Outlet />} />
                    <Route path="/carrinho/:id_cart?" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/checkout/sucesso" element={<CheckoutSuccess />} />
                    <Route path="/produtos" element={<Category />} />
                    <Route path="/novidades" element={<Category />} />
                    <Route path="/livelo" element={<Category />} />
                    <Route path="/nunca-usados" element={<Category />} />
                    <Route path="/nunca-usados" element={<Category />} />
                    <Route path="/categorias/nunca-usados" element={<Category />} />
                    <Route path="/promocoes" element={<Category />} />
                    <Route path="/categorias/:section/:category?" element={<Category />} />
                    <Route path="/marcas/:section/:category?" element={<Category />} />
                    <Route path="/ocasioes/:section/:category?" element={<Category />} />
                    <Route path="/loja/:section" element={<Category />} />
                    <Route path="/produto/:product_slug" element={<Product />} />
                    <Route path="/desapegue" element={<Desapegue />} />
                    <Route path="/o-que-vender" element={<Marcas />} />
                    <Route path="/pagamentos" element={<Pagamentos />} />
                    <Route path="/box/:box_type?" element={<Box />} />
                    <Route path="/blog" element={<Recreio />} />
                    <Route path="/newsletter" element={<Recreio />} />
                    <Route path="/blog/post/:post_slug" element={<BlogPost />} />
                    <Route path="/quiz/:current_step?" element={<QuizPage />} />
                    <Route path="/quiz/:current_step?/sucesso" element={<QuizPage />} />
                    <Route path="/quero-vender" element={<SellerSignup />} />
                    <Route path="/quero-vender/sucesso" element={<SellerSignup />} />
                    <Route path="/login" element={!authenticated ? <RenderLoginModal /> : <Navigate to="/" />} />
                    <Route path="/reset_password/" element={authenticated ? <Navigate to="/" /> : <PasswordReset />} />
                    <Route
                        path="/login_callback"
                        element={!authenticated ? <RenderLoginCallback /> : <Navigate to="/" />}
                    />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/cadastro" element={<Navigate to="/cadastro/email" />} />
                    <Route path="/cadastro/email" element={<Signup />} />
                    <Route
                        path="/cadastro/verificar"
                        element={
                            <PrivateRoute>
                                <LoginVerification />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/faq/:current_tab?" element={<Faq />} />
                    <Route
                        path="/minha-conta/:current_tab?/:item_id?"
                        element={
                            <PrivateRoute>
                                <Account />
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        ),

        // eslint-disable-next-line
        [authenticated]
    );

    return (
        <>
            <Helmet>
                <title>Re Petit: roupas e acessórios gentilmente usados para bebês e crianças</title>
                <meta
                    property="og:title"
                    content="Re Petit: roupas e acessórios gentilmente usados para bebês e crianças"
                />
                <meta
                    name="keywords"
                    content="repetit, re petit, brechó, brechó infantil, brechó bebês, brechós, brechós infantis, roupa, bebê, bebê conforto, criança, adolescente, berço, enxoval, usado, segunda mão, novo, seminovo, comprar, vender"
                />
                <meta
                    name="description"
                    content="Na Re Petit você encontra roupas e acessórios infantis gentilmente usados para bebês e crianças. Venha recircular peças das melhores marcas nacionais e internacionais com a gente!"
                />
                <meta
                    property="og:description"
                    content="Na Re Petit você encontra roupas e acessórios infantis gentilmente usados para bebês e crianças. Venha recircular peças das melhores marcas nacionais e internacionais com a gente!"
                />
                <meta property="og:url" content="https://repetit.com.br/" />
                <meta property="og:image" content="https://www.repetit.com.br/images/logo-repetit200.png" />
            </Helmet>

            {memoizedApp}

            <LoginModal />

            <CookiesModal />

            {modal && (
                <AlertModal
                    {...modal}
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => {
                        modal.callback && modal.callback();
                        dispatch(ModalActions.close());
                    }}
                />
            )}

            {currentRoute !== "/carrinho" && currentRoute !== "/checkout" ? (
                <WhatsappButton
                    onClick={async () => {
                        // eslint-disable-next-line
                        let userSession = window.userCurrent(user);
                        let userSessionServerData = window.userCurrentServerData(user);
                        let event_id = generateUUID();
                        let event_time = Math.round(Date.now() / 1000);

                        window.fbq("trackCustom", "Contact", {}, { eventID: event_id });

                        api.post("/facebook_ads_event", {
                            data: [
                                {
                                    event_name: "Contact",
                                    event_id: event_id,
                                    event_time: event_time,
                                    event_source_url: window.location.href,
                                    action_source: "website",
                                    user_data: userSessionServerData,
                                },
                            ],
                        })
                            .then((res) => console.log("EVENTO WhatsappButton", res.data))
                            .catch((res) => res.response);

                        // eslint-disable-next-line
                        dataLayer.push({
                            event: "Whatsapp Button",
                        });

                        let phone_number = "5511990085518";

                        if (["/pagamentos", "/o-que-vender", "/desapegue"].includes(window.location.pathname)) {
                            phone_number = "5511976922404";
                        }

                        window.open(`https://api.whatsapp.com/send?phone=${phone_number}`, "_blank");
                    }}
                >
                    <img alt="Whatsapp Logo" src={WhatsappIcon} />
                </WhatsappButton>
            ) : (
                ""
            )}
        </>
    );
}

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            await dispatch(UserActions.logout());
            navigate("/");
        };

        handleLogout();
    }, [dispatch, navigate]);

    // You can return some loading indicator or null here if needed
    return null;
};

const RenderLoginModal = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { NavigateUrl } = location.state || {};
    dispatch(LoginModalActions.show(NavigateUrl));
    navigate("/");
    return null;
};

const RenderLoginCallback = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const error = queryParams.get("error");

    const loadProfile = async () => {
        try {
            let login_response = await api.get("/profiles/me", {
                headers: {
                    Authorization: token,
                },
            });

            if (login_response.status === 200) {
                dispatch(UserActions.login({ token, ...login_response.data }));
                dispatch(UserActions.refreshProfile());
            } else {
                dispatch(UserActions.logout());
            }
        } catch (error) {
            console.error("Error:", error);
            dispatch(UserActions.logout());
        }
    };

    useEffect(() => {
        if (!error && token) loadProfile();
        else if (error) {
            dispatch(
                ModalActions.show({
                    show: true,
                    message: (
                        <>
                            Não foi possível entrar utilizando sua rede social.
                            <br />
                            <br />
                            {error}
                        </>
                    ),
                })
            );
        }
        navigate("/");
        // eslint-disable-next-line
    }, []);

    return null;
};

const WhatsappButton = styled.div`
    cursor: pointer;
    position: fixed;
    right: 25px;
    bottom: 25px;
    width: 72px;
    height: 72px;
    object-fit: contain;
    filter: drop-shadow(0px 1px 3px rgba(52, 52, 59, 0.1));

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    ${isMobileMediaQuery} {
        right: 18px;
        bottom: 18px;
        width: 52px;
        height: 52px;
    }
`;

export default App;
