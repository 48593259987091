import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CookieService } from "../services/cookie";

export const useWatchUtm = () => {
    const { search } = useLocation();

    function findUtmParams(search) {
        const urlParams = new URLSearchParams(search);

        const utmSource = urlParams.get("utm_source");
        const utmMedium = urlParams.get("utm_medium");
        const utmCampaign = urlParams.get("utm_campaign");
        const utmContent = urlParams.get("utm_content");
        CookieService.set("last_source", utmSource);
        CookieService.set("last_medium", utmMedium);
        CookieService.set("last_campaign", utmCampaign);
        CookieService.set("last_content", utmContent);
    }

    useEffect(() => {
        const acceptedCookies = JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false;

        if (!acceptedCookies) return;

        findUtmParams(search);
    }, []);
};
