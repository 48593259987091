import store from "../redux/store";
import axios from "axios";

export const tryParse = (value, defaultValue = null) => {
    try {
        return JSON.parse(value);
    } catch (error) {
        switch (defaultValue) {
            case "self":
                return value;

            default:
                return null;
        }
    }
};

export const validSession = () => {
    const user = store.getState((state) => state).user;

    let authenticated = false;

    if (user.token && user.token.length > 0) {
        var base64Url = user.token && user.token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        try {
            jsonPayload = JSON.parse(jsonPayload);

            let now = Date.now() / 1000;

            if (jsonPayload.exp && now < jsonPayload.exp) {
                authenticated = true;
            }
        } catch (error) {}
    }

    return authenticated;
};

export const validatePassword = (password) => {
    let match_rules = false;

    if (!password || password.trim() === "" || password.trim().length < 6) return match_rules;

    match_rules =
        password.match(/[A-Z]*/g) &&
        password.match(/[A-Z]*/g).filter((item) => item !== null && item !== "").length > 0;
    match_rules =
        match_rules &&
        password.match(/[a-z]*/g) &&
        password.match(/[a-z]*/g).filter((item) => item !== null && item !== "").length > 0;
    match_rules =
        match_rules &&
        password.match(/[0-9]*/g) &&
        password.match(/[0-9]*/g).filter((item) => item !== null && item !== "").length > 0;
    match_rules =
        match_rules &&
        password.match(/[![\]@#$%^&*(),.?":;{}=+|<>_-]/g) &&
        password.match(/[![\]@#$%^&*(),.?":;{}=+|<>_-]/g).filter((item) => item !== null && item !== "").length > 0;
    match_rules = match_rules && password.length >= 6;

    return match_rules;
};

export const capitalize = function (string, all = false) {
    string = string.toLowerCase();

    if (!all) return string.charAt(0).toUpperCase() + string.slice(1);
    else
        return string
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
};

export const brazilianStates = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RO",
    "RS",
    "RR",
    "SC",
    "SE",
    "SP",
    "TO",
];

export const identifyZipCode = async (zip_code) => {
    const zipCodeServiceUrl = `https://viacep.com.br/ws/${zip_code}/json/`;

    let zip_response = await axios.get(zipCodeServiceUrl).catch((err) => err.response);

    if (zip_response && zip_response.status === 200) return zip_response.data;
    else return { error: true, status: zip_response?.status || null };
};

export const isValidCPF = (cpf) => {
    if (typeof cpf !== "string") return false;

    cpf = cpf.replace(/[\s.-]*/gim, "");

    if (cpf.length !== 11 || !Array.from(cpf).filter((e) => e !== cpf[0]).length) {
        return false;
    }

    let count = 0;

    let leftover;

    for (let i = 1; i <= 9; i++) {
        count = count + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    leftover = (count * 10) % 11;

    if (leftover === 10 || leftover === 11) leftover = 0;

    if (leftover !== parseInt(cpf.substring(9, 10))) return false;

    count = 0;

    for (let i = 1; i <= 10; i++) {
        count = count + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    leftover = (count * 10) % 11;

    if (leftover === 10 || leftover === 11) leftover = 0;

    if (leftover !== parseInt(cpf.substring(10, 11))) return false;

    return true;
};

export const isValidEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const openPrivacyNTerms = (type) => {
    if (type === "privacy") {
        window.open("/politica-de-privacidade.pdf", "_blank", "noopener");
    } else if (type === "terms") {
        window.open("/termos-de-uso.pdf", "_blank", "noopener");
    } else if (type === "promotions") {
        window.open("/regras-promocoes-e-cupons.pdf", "_blank", "noopener");
    } else if (type === "livelo") {
        window.open("/regulamento-livelo.pdf", "_blank", "noopener");
    }

    return null;
};

export function getImageTypeFromUrl(imageUrl) {
    const extension = imageUrl.split(".").pop().split("?")[0];
    switch (extension.toLowerCase()) {
        case "jpg":
        case "jpeg":
            return "image/jpeg";
        case "png":
            return "image/png";
        case "gif":
            return "image/gif";
        case "webp":
            return "image/webp";
        case "bmp":
            return "image/bmp";
        case "svg":
            return "image/svg+xml";
        default:
            return "";
    }
}
