export const CookieService = {
    get(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
        return null;
    },

    set(name, value, days = 3, path = "/") {
        const domain = ".repetit.com.br";
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie = `${name}=${value || ""}${expires}; domain=${domain}; path=${path}`;
    },

    remove(name, path = "/") {
        document.cookie = `${name}=; Max-Age=-99999999; path=${path}`;
    },

    getAll() {
        const cookies = document.cookie.split("; ");
        return cookies.reduce((acc, cookie) => {
            const [key, value] = cookie.split("=");
            acc[key] = value;
            return acc;
        }, {});
    },
};
